.map-block-container {
    padding: 0;
}

.store-window__image {
    max-width: 100%;
}
/* 
.ymaps-2-1-73-balloon-overlay {
    min-width: 330px;
} */

.store-window {
    min-width: 300px;
}